import { template as template_f86c3169324646a0b9bf89b94699b6ff } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_f86c3169324646a0b9bf89b94699b6ff(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
