import { template as template_8fb48e5d34dc4cc795220e44f44b5461 } from "@ember/template-compiler";
const WelcomeHeader = template_8fb48e5d34dc4cc795220e44f44b5461(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
